// VirtualizedAutocomplete.jsx

import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import Popper from "@mui/material/Popper";
import { useTheme, styled } from "@mui/material/styles";
import { VariableSizeList } from "react-window";
import Typography from "@mui/material/Typography";
import { Avatar, Box, Chip } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Groups } from "@mui/icons-material";
import { UserAvatar } from "../UserAvatar";

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
/* eslint-disable-next-line */
const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
) {
    const { children, ...other } = props;
    const itemData = [];
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 40 : 48;

    const getChildSize = (child) => {
        /* eslint-disable-next-line */
        if (child.hasOwnProperty("group")) {
            return 48;
        }
        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

ListboxComponent.propTypes = {
    children: PropTypes.node,
};

const StyledPopper = styled(Popper)(({ theme }) => ({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        "& ul": {
            padding: 0,
            margin: 0,
        },
    },
    width: "var(--autocomplete-width)",
}));

export default function VirtualizedAutocomplete(props) {
    const {
        options,
        emailError,
        onChange,
        emailsValues,
        textValue,
        onInputChange,
        filterOptions,
    } = props;
    const width = 453;
    return (
        <Autocomplete
            sx={{ width }}
            disableListWrap
            options={options}
            getOptionLabel={(option) => option.email}
            onChange={onChange}
            size="small"
            value={emailsValues}
            inputValue={textValue}
            onInputChange={onInputChange}
            freeSolo
            id="share-label-emails"
            disableClearable
            autoHighlight
            disableCloseOnSelect
            filterOptions={filterOptions}
            multiple
            fullWidth
            renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => {
                    return (
                        <Chip
                            {...getTagProps({ index })}
                            size={"small"}
                            key={index}
                            sx={{
                                backgroundColor:
                                    option.error && "rgba(236, 56, 40, 0.2)",
                            }}
                            label={
                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    sx={{ marginLeft: "-5px", alignItems: "center" }}
                                >
                                    {!option.group ? (
                                        <UserAvatar alt={option.email} sx={{ width: 20, height: 20 }} src={option.photo} />
                                    ) : (
                                        <Avatar sx={{ width: 20, height: 20, background: '#E8F0FE' }}>
                                            <Groups sx={{ width: 16, height: 16, color: '#3B7DED' }} />
                                        </Avatar>
                                    )}
                                    <Typography
                                        sx={{
                                            color: "rgba(0, 0, 0, 0.87)",
                                            fontSize: 13,
                                            fontFamily: "Roboto",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "18px",
                                            letterSpacing: "0.16px",
                                        }}
                                    >
                                        {option.email}
                                    </Typography>
                                </Stack>
                            }
                        />
                    );
                });
            }}
            renderOption={(props, option, state) => (
                <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                    key={state.index}
                >
                    {!option.group ? (
                        <UserAvatar alt={option.name || option.email} sx={{ width: 24, height: 24 }} src={option.photo} />
                    ) : (
                        <Avatar sx={{ width: 24, height: 24, background: '#E8F0FE' }}>
                            <Groups sx={{ width: 18, height: 18, color: '#3B7DED' }} />
                        </Avatar>
                    )}
                    <Typography
                        variant={"settingsMenu"}
                        sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                        ml={2}
                    >
                        {option.name
                            ? `${option.name} (${option.email})`
                            : option.email}
                    </Typography>
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={emailError}
                    label="Add emails"
                    variant={"outlined"}
                    placeholder=""
                    InputProps={{
                        ...params.InputProps,
                        inputProps: {
                            ...params.inputProps,
                            "data-lpignore": "true",
                        },
                        sx: {
                            color: "rgba(0, 0, 0, 0.84)",
                            fontSize: "16px",
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "24px",
                            letterSpacing: "0.15px",
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: emailError
                                ? "rgba(211, 47, 47, 1)"
                                : "rgba(0, 0, 0, 0.60)",
                            fontSize: "16px",
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: 400,
                            letterSpacing: "0.15px",
                        },
                    }}
                />
            )}
            ListboxComponent={ListboxComponent}
            PopperComponent={(props) => (
                <StyledPopper
                    {...props}
                    style={{ "--autocomplete-width": `${width}px` }}
                />
            )}
        />
    );
}
